import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumb from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import CommonModal from "../../../components/CommonModal";
import CommonPageTitle from "../../../components/CommonPageTitle";
import TextArea from "../../../components/TextArea";
import UserService from "../../../services/UserService";
import "../../style.scss";
import "./style.scss";
import Form from "react-bootstrap/Form";
import { AiOutlineDownload } from "react-icons/ai";
import CommonFileAccordian from "../../../components/CommonFileAccordian";
import CommonRequestDetails from "../../../components/CommonRequestDetails";
import TextBox from "../../../components/TextBox";
import PropulsionEngine from "./PropulsionEngine";
import { useLazyFetchUserbyIdQuery } from "../../../services/modules/admin/users";
import { useLazyFetchVesselBuilderbyIdQuery } from "../../../services/modules/admin/vessel";
import {
  useLazyFetchVesselAdditionalDetailsbyIdQuery,
  useLazyFetchVesselbyIdQuery,
  useUpdateVesselAdditionalDetailMutation,
} from "../../../services/modules/vesselowner/preapproval";
import {
  useApproveStageVerificationbyNAMutation,
  useRejectStageVerificationbyNAMutation,
  useLazyFetchStageVerificationbyIdQuery,
  useUpdateStageVerificationInformMutation,
} from "../../../services/modules/StageVerification";
import {
  cocGrade,
  NAVAL_ARCHITECTURE,
  VESSEL_OWNER,
} from "../../../utils/constants";
import { getRole } from "../../../utils/utility";
import { Accordion, ListGroup } from "react-bootstrap";
import StageVerificationPreApproval from "../../../components/StageVerificationPreApproval";
import { useLazyFetchYardbyIdQuery } from "../../../services/modules/admin/yard";
import FileSelector, { DummyFile } from "../../../components/FileSelector";
import { uploadFilesInSchema } from "../../../utils/uploadFilesInSchema";
import { useLazyDownloadFileQuery } from "../../../services/modules/common/file";
import Loader from "../../../components/Loader";
import CheckBox from "../../../components/CheckBox";
import { updateVesselAdditionalDetail } from "../../../services/modules/vesselowner/preapproval/PreapprovalRequest";
import TextBoxLive from "../../../components/TextBoxLive";
import SelectBox from "../../../components/Selectbox";

function StageVerificationDetails({ ...props }) {
  const [isApproved, setIsApproved] = useState(true);
  const role = getRole();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Id: stageVerificationId } = state || {};
  const { vesselId } = state || {};
  const [userInfo, setUserInfo] = useState(null);
  const [vesselData, setVesselData] = useState(null);
  const [vesselDataAdditional, setVesselDataAdditional] = useState(null);
  const [vesselAddId, setVesselAddId] = useState(null);
  const [propulsionEngine, setPropulsionEngine] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [builderId, setBuilderId] = useState("");
  const [builderDetails, setBuilderDetails] = useState([]);
  const [userId, setUserId] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [stageVerificationDetails, setStageVerificationDetails] = useState([]);
  const [status, setStatus] = useState(false);
  const [type, setType] = useState(null);

  const [approveStageVerificationbyNA] =
    useApproveStageVerificationbyNAMutation();

  const [rejectStageVerificationbyNA] =
    useRejectStageVerificationbyNAMutation();

  const [fetchVesselbyId, { data: vesselDataFetched, isSuccess }] =
    useLazyFetchVesselbyIdQuery();

  const [fetchVesselAdditionalDetailsbyId, { data: vesselAdditionalData }] =
    useLazyFetchVesselAdditionalDetailsbyIdQuery();

  const [fetchVesselBuilderbyId, { data: builderData }] =
    useLazyFetchVesselBuilderbyIdQuery();

  const [fetchYardbyId, { data: YardData }] = useLazyFetchYardbyIdQuery();

  const [fetchUserbyId, { data: userDataById }] = useLazyFetchUserbyIdQuery();

  const [fetchStageVerificationbyId, { data: stageVerificationDatabyId }] =
    useLazyFetchStageVerificationbyIdQuery();

  const [updateStageVerificationInform] =
    useUpdateStageVerificationInformMutation();

  const [
    updateVesselAdditionalDetail,
    {
      isError: isUpdateVesselAdditionalError,
      error: updateVesselAdditionalError,
      isLoading: isUpdateVesselAdditionalLoading,
    },
  ] = useUpdateVesselAdditionalDetailMutation();

  const [message, setMessage] = useState({
    title: "",
    isSuccess: true,
    show: false,
  });

  const onHandleClickBack = () => navigate(-1);

  const documentUploadFiles = [
    {
      fileTitle: "heelTest",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "swampTest",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "inclinationTest",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "calculationReport",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "calculationReportWithFlooding",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "damageStabilityCalculations",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "grossTonnageCalculation",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "netTonnageCalculation",
      fileType: "application/pdf",
      fileName: "",
    },
  ];

  const initialValues = {
    remarks: "",
    remarksVo: "",
    naval_architect: "",
    expectedVisitDate: "",
    photos: [],
    documentUpload: documentUploadFiles,
    LengthOfVessel: "",
    breadthOfVessel: "",
    depthOfVessel: "",
    free_board_marking_vessel: false,
    grossTonnage: "",
    lowerDeck: "",
    upperDeck: "",
    netTonnage: "",
    name: "",
    cocGrade: "",
    no: "",
    cocNo: "",
    placeOfIssue: "",
    engineDriverName: "",
    engineDriverCocGrade: "",
    engineCocNo: "",
    placeOfIssueEngine: "",
    minimumFreeboard: "",
    zoneOfOperation: "",
  };

  const validationSchema = isApproved
    ? Yup.object({
        remarks: Yup.string().when("action", {
          is: (action) => action === "approve" || action === "reject",
          then: () =>
            Yup.string()
              .required("Remarks field is required!")
              .matches(
                /.*\S.*/,
                "Remark cannot be empty or consist of only spaces."
              ),
        }),
        expectedVisitDate: Yup.date().when("action", {
          is: (action) => action === "inform",
          then: () => Yup.date().required("Visit date is required"),
        }),

        // documentUpload: Yup.array(Yup.mixed()).when("vesselData", {
        //   is: (vesselData) => {
        //     console.log("Vessel Data:", vesselData); // Debugging
        //     return vesselData?.vesselCategory?.vesselCategoryName !== "Category A";
        //   },
        //   then: Yup.array()
        //     .of(
        //       Yup.object().shape({
        //         fileTitle: Yup.string().oneOf([
        //           "heelTest",
        //           "swampTest",
        //           "inclinationTest",
        //           "calculationReport",
        //           "calculationReportWithFlooding",
        //           "damageStabilityCalculations",
        //           "grossTonnageCalculation",
        //           "netTonnageCalculation",
        //         ]),
        //         fileType: Yup.string().oneOf(["application/pdf"]),
        //         fileName: Yup.mixed()
        //           .required("File is required")
        //           .test("fileFormat", "Only pdf files are allowed", (file) => {
        //             if (file) return file.type.match("application/pdf");
        //             return true;
        //           }),
        //       })
        //     )
        //     .length(8, "All files are required"),
        // }),
        LengthOfVessel: Yup.string().when(["action", "type"], {
          is: (action) => {
            return action === "approve" && type === "Launching";
          },
          then: () =>
            Yup.string()
              .required("Length is required")
              .matches(
                /^(0|NA|[1-9]\d*(\.\d{1,4})?|0?\.\d{1,4})$/,
                "Please enter a valid number(max 4 decimal places)"
              ),
          otherwise: () => Yup.string(),
        }),
        breadthOfVessel: Yup.string().when(["action", "type"], {
          is: (action) => action === "approve" && type === "Launching",
          then: () =>
            Yup.string()
              .required("Breadth is required")
              .matches(
                /^(0|NA|[1-9]\d*(\.\d{1,4})?|0?\.\d{1,4})$/,
                "Please enter a valid number(max 4 decimal places)"
              ),
          otherwise: () => Yup.string(),
        }),
        depthOfVessel: Yup.string().when(["action", "type"], {
          is: (action) => action === "approve" && type === "Launching",
          then: () =>
            Yup.string()
              .required("Depth is required")
              .matches(
                /^(0|NA|[1-9]\d*(\.\d{1,4})?|0?\.\d{1,4})$/,
                "Please enter a valid number(max 4 decimal places)"
              ),
          otherwise: () => Yup.string(),
        }),
        lowerDeck: Yup.string().when(["action", "type"], {
          is: (action) => action === "approve" && type === "Stability",
          then: () =>
            Yup.string()
              .required("Upper Deck is required!")
              .matches(
                /^(0|NA|[1-9]\d*(\.\d{1,4})?|0?\.\d{1,4})$/,
                "Please enter a valid number (max 4 decimal places)"
              ),
          otherwise: () => Yup.string(),
        }),
        grossTonnage: Yup.string().when(["action", "type"], {
          is: (action) => action === "approve" && type === "Stability",
          then: () =>
            Yup.string()
              .required("Gross Tonnage is required!")
              .matches(
                /^(0|NA|[1-9]\d*(\.\d{1,4})?|0?\.\d{1,4})$/,
                "Please enter a valid number (max 4 decimal places)"
              ),
          otherwise: () => Yup.string(),
        }),
        upperDeck: Yup.string().matches(
          /^(0|NA|[1-9]\d*(\.\d{1,4})?|0?\.\d{1,4})$/,
          "Please enter a valid number (max 4 decimal places)"
        ),
        netTonnage: Yup.string().matches(
          /^(0|NA|[1-9]\d*(\.\d{1,4})?|0?\.\d{1,4})$/,
          "Please enter a valid number (max 4 decimal places)"
        ),
        // free_board_marking_vessel: Yup.string().when(["action", "type"], {
        //   is: (action, type) => action === "approve" && type === "Launching",
        //   then: () =>
        //     Yup.boolean().oneOf(
        //       [true],
        //       "Free board marking vessel is required "
        //     ),
        //   otherwise: () => Yup.string(),
        // }),
      })
    : Yup.object({
        remarks: Yup.string().required("Remarks field is required!"),
      });

  const onSubmit = async (values, { setSubmitting }) => {
    if (formik.values.action === "approve") {
      const { remarks, documentUpload, photos } = values;
      if (remarks) {
        let postData = {
          remarks: [
            {
              remark: remarks,
              action: "approve",
            },
          ],
        };
        if (type === "Stability") {
          postData["documentUpload"] = documentUpload;
          postData["photos"] = photos;
          const uploadedPostdata = await uploadFilesInSchema(postData);
          postData = uploadedPostdata;
          const postDataAdditional = {
            passengerCapacity: {
              lowerDeck: values.lowerDeck,
              upperDeck: values.upperDeck,
              grossTonnage: values.grossTonnage,
              netTonnage: values.netTonnage,
            },
            masterDetails: {
              cocGrade: values.cocGrade,
              number: values.no,
              cocNumber: values.cocNo,
              placeOfIssue: values.placeOfIssue,
            },
            engineDriverDetails: {
              name: values.engineDriverName,
              cocGrade: values.engineDriverCocGrade,
              number: values.engineCocNo,
              cocNumber: values.engineCocNo,
              placeOfIssue: values.placeOfIssueEngine,
            },
            hulls: {
              minimumFreeboard: values.minimumFreeboard,
            },

            zoneOfOperation: values.zoneOfOperation,
          };
          try {
            const updateRes = await updateVesselAdditionalDetail({
              data: postDataAdditional,
              id: vesselAddId,
            });
          } catch (error) {}
        }
        if (type === "Launching") {
          const {
            LengthOfVessel,
            breadthOfVessel,
            depthOfVessel,
            free_board_marking_vessel,
          } = values;
          let LaunchingPostData = {
            actualMesured: {
              LengthOfVessel: LengthOfVessel,
              breadthOfVessel: breadthOfVessel,
              depthOfVessel: depthOfVessel,
              freeBoardMarking: free_board_marking_vessel,
            },
          };
          const response = await updateVesselAdditionalDetail({
            id: vesselAddId,
            data: LaunchingPostData,
          });
          if (response?.error) {
            console.log(
              "Failed to update vessel additional details:",
              response
            );
          } else if (response?.statusCode === 200) {
            console.log("Vessel additional details updated successfully.");
          }
        }

        try {
          const response = await approveStageVerificationbyNA({
            data: postData,
            id: stageVerificationId,
          });
          if (response?.error) {
            setMessage({
              title: response?.error?.data?.message,
              show: true,
              isSuccess: false,
            });
          } else {
            setMessage({
              title: response?.data?.message,
              show: true,
              isSuccess: true,
            });
          }
        } catch (error) {}
        setSubmitting(false);
      }
    } else if (formik.values.action === "reject") {
      onReject(values);
    } else if (formik.values.action === "inform") {
      const postData = {
        expectedVisitDate: formik.values.expectedVisitDate,
      };
      const res = await updateStageVerificationInform({
        id: stageVerificationId,
        data: postData,
      });
      if (res?.error) {
        setMessage({
          title: res?.error?.data?.message,
          show: true,
          isSuccess: false,
        });
      } else {
        setMessage({
          title: res?.data?.message,
          show: true,
          isSuccess: true,
        });
      }
      return;
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  const { setFieldValue } = formik;

  const onReject = async () => {
    const { remarks } = formik.values;
    if (remarks) {
      let postData = {
        remarks: [
          {
            remark: remarks,
            action: "reject",
          },
        ],
        // length: formik.values.length,
        // breadth: formik.values.breadth,
        // depth: formik.values.depth,
        // free_board_marking_vessel: formik.values.free_board_marking_vessel,
      };

      try {
        const response = await rejectStageVerificationbyNA({
          data: postData,
          id: stageVerificationId,
        });
        if (response?.error) {
          setMessage({
            title: response?.error?.data?.message,
            show: true,
            isSuccess: false,
          });
        } else {
          setMessage({
            title: response?.data?.message,
            show: true,
            isSuccess: true,
          });
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchStageVerificationbyId({ id: stageVerificationId });
  }, [stageVerificationId, fetchStageVerificationbyId]);

  useEffect(() => {
    fetchVesselbyId({ id: vesselId });
  }, [fetchVesselbyId, vesselId]);

  useEffect(() => {
    if (
      stageVerificationDatabyId?.statusCode === 201 ||
      stageVerificationDatabyId?.statusCode === 200
    ) {
      const { result } = stageVerificationDatabyId;
      setStageVerificationDetails(stageVerificationDatabyId?.result);
      const stageStatus = stageVerificationDatabyId?.result?.status;

      setStatus(stageStatus);
      setType(stageVerificationDatabyId?.result?.type);

      const remarks = stageVerificationDatabyId?.result?.remarks;

      const remarksVo = remarks
        .filter((remark) => remark.userId === userId)
        .map((remark) => remark.remark);

      const remarksNav = remarks
        .filter(
          (remark) =>
            remark.userId === stageVerificationDatabyId?.result?.userId
        )
        .map((remark) => remark.remark);

      if (remarksNav?.length > 0) {
        formik.setFieldValue("remarks", remarksNav?.at(0));
      }
      if (remarksVo?.length > 0) {
        formik.setFieldValue("remarksVo", remarksVo?.at(0));
      }

      if (result?.expectedVisitDate)
        formik.setFieldValue(
          "expectedVisitDate",
          convertDate(result.expectedVisitDate)
        );
    }
  }, [stageVerificationDatabyId, userId]);

  useEffect(() => {
    if (
      vesselAdditionalData?.statusCode === 201 ||
      vesselAdditionalData?.statusCode === 200
    ) {
      const { result } = vesselAdditionalData;
      console.log("result", result);

      formik.setFieldValue("minimumFreeboard", result?.hulls?.minimumFreeboard);
      formik.setFieldValue("zoneOfOperation", result?.zoneOfOperation);
      formik.setFieldValue("name", result?.masterDetails?.name);
      formik.setFieldValue("cocGrade", result?.masterDetails?.cocGrade);
      formik.setFieldValue("no", result?.masterDetails?.number);
      formik.setFieldValue("cocNo", result?.masterDetails?.cocNumber);
      formik.setFieldValue("placeOfIssue", result?.masterDetails?.placeOfIssue);
      formik.setFieldValue(
        "engineDriverName",
        result?.engineDriverDetails?.name
      );
      formik.setFieldValue(
        "engineDriverCocGrade",
        result?.engineDriverDetails?.cocGrade
      );
      formik.setFieldValue(
        "engineCocNo",
        result?.engineDriverDetails?.cocNumber
      );
      formik.setFieldValue(
        "placeOfIssueEngine",
        result?.engineDriverDetails?.placeOfIssue
      );
    }
  }, [vesselAdditionalData]);

  useEffect(() => {
    if (
      vesselDataFetched?.statusCode === 201 ||
      vesselDataFetched?.statusCode === 200
    ) {
      setUserId(vesselDataFetched?.result?.at(0)?.ownerId);
      const { vesselAdditionalDetail } = vesselDataFetched?.result?.at(0);
      setVesselData(vesselDataFetched?.result?.at(0));
      setBuilderId(vesselDataFetched?.result?.at(0)?.builderId);
      setVesselAddId(vesselAdditionalDetail._id);
      fetchVesselAdditionalDetailsbyId({
        id: vesselAdditionalDetail._id,
      });
    }
  }, [vesselDataFetched, fetchVesselAdditionalDetailsbyId]);
  useEffect(() => {
    if (
      vesselAdditionalData?.statusCode === 201 ||
      vesselAdditionalData?.statusCode === 200
    ) {
      setVesselDataAdditional(vesselAdditionalData?.result);
      setPropulsionEngine(vesselAdditionalData?.result?.propulsionOfEngines);
      setEquipments(vesselAdditionalData?.result?.equipment);
    }
  }, [vesselAdditionalData]);

  useEffect(() => {
    fetchUserbyId({ id: userId });
  }, [userId, fetchUserbyId]);

  useEffect(() => {
    if (userDataById?.statusCode === 201 || userDataById?.statusCode === 200) {
      setUserDetails(userDataById?.result?.[0]);
    }
  }, [userDataById]);

  useEffect(() => {
    fetchVesselBuilderbyId({ id: builderId });
  }, [builderId, fetchVesselBuilderbyId]);

  useEffect(() => {
    if (!isSuccess) return;
    fetchYardbyId({
      id: vesselDataFetched?.result?.at(0)?.yardId,
    });
  }, [fetchYardbyId, isSuccess, vesselDataFetched?.result?.at(0)?.yardId]);

  useEffect(() => {
    if (builderData?.statusCode === 201 || builderData?.statusCode === 200) {
      setBuilderDetails(builderData?.result[0]);
    }
  }, [builderData]);

  useEffect(() => {
    if (userInfo) {
      setFieldValue("owner_name", userInfo?.given_name);
    }
  }, [userInfo, setFieldValue]);
  useEffect(() => {
    const getUserInfo = async () => {
      const userDetail = await UserService.getUserInfo();
      if (userDetail) {
        setUserInfo(userDetail);
      }
    };
    getUserInfo();
  }, []);
  const isTouched = (fieldName, formik) => {
    const isCondition =
      formik.touched?.[fieldName] && formik.errors?.[fieldName];
    return isCondition;
  };
  const handleClose = () => {
    setMessage((message) => ({
      ...message,
      show: false,
    }));

    //Navigate based on the type
    switch (type) {
      case "Keel laying":
        navigate("/keel-laying-requests");
        break;
      case "Launching":
        navigate("/launching-requests");
        break;
      case "Stability":
        navigate("/stability-requests");
        break;
      default:
        break;
    }
  };

  const convertDate = (date) => {
    if (date) return new Date(date)?.toISOString()?.split("T")?.at(0);
    else return null;
  };

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  // {
  //   label: "Photos (maximum upto 6)",
  //   value: "photos",
  //   multiple: 6,
  //   accept: "image/*",
  // },

  const stabilityDocuments = [
    {
      label: "Heel Test",
      value: "documentUpload.[0].fileName",
      accept: "application/pdf",
      field: "heelTest",
    },
    {
      label: "Swamp Test",
      value: "documentUpload.[1].fileName",
      accept: "application/pdf",
      field: "swampTest",
    },
    {
      label: "Inclining Test / Inclination test",
      value: "documentUpload.[2].fileName",
      accept: "application/pdf",
      field: "inclinationTest",
    },
    {
      label: "Calculations report Heel & Inclining Test / Inclination test",
      value: "documentUpload.[3].fileName",
      accept: "application/pdf",
      field: "calculationReport",
    },
    {
      label: "Calculation report with flooding",
      value: "documentUpload.[4].fileName",
      accept: "application/pdf",
      field: "calculationReportWithFlooding",
    },
    {
      label: "Damage stability calculations",
      value: "documentUpload.[5].fileName",
      accept: "application/pdf",
      field: "damageStabilityCalculations",
    },
    {
      label: "Gross Tonnage Calculation",
      value: "documentUpload.[6].fileName",
      accept: "application/pdf",
      field: "grossTonnageCalculation",
    },
    {
      label: "Net tonnage Calculation",
      value: "documentUpload.[7].fileName",
      accept: "application/pdf",
      field: "netTonnageCalculation",
    },
  ];

  function getFieldLabel(field) {
    return stabilityDocuments.find((doc) => doc.field === field);
  }

  const [downloadFile, { isFetching }] = useLazyDownloadFileQuery();

  const handleDownloadClick = (fileName) => {
    downloadFile({ fileName })
      .then((response) => {
        window.open(response.data, "_blank");
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  console.log("errora", formik.errors);

  if (isFetching || formik.isSubmitting) return <Loader />;

  return (
    <Container>
      <Breadcrumb crumbs={props.crumbs} />
      <CommonModal
        {...message}
        description=""
        buttonText="OK"
        handleClose={handleClose}
      />
      {role !== VESSEL_OWNER && (
        <CommonPageTitle
          title="Back"
          arrowback={true}
          handleClickBack={onHandleClickBack}
        />
      )}
      {role === VESSEL_OWNER ? (
        <StageVerificationPreApproval
          stageVerificationDetails={stageVerificationDetails}
          // stageStatus={stageStatus}
          vesselData={vesselData}
          vesselAdditionalDetail={vesselAddId}
          vesselAllDetails={vesselDataAdditional}
        />
      ) : null}
      {role === NAVAL_ARCHITECTURE ? (
        <>
          <CommonRequestDetails
            userDetails={userDetails}
            vesselData={vesselData}
            builderDetails={builderDetails}
            yardDetails={YardData?.result}
            vesselDataAdditional={vesselDataAdditional}
            propulsionEngine={propulsionEngine}
            equipments={equipments}
          />
          <CommonFileAccordian vesselDataAdditional={vesselDataAdditional} />
        </>
      ) : null}

      {role === NAVAL_ARCHITECTURE && stageVerificationDetails ? (
        <Container className="p-0">
          <Row>
            <form className="forms" onSubmit={formik.handleSubmit}>
              <Row>
                <div className="forms py-3 px-4">
                  <div className="card stage-verification">
                    <Accordion defaultActiveKey={["0"]} alwaysOpen flush>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Stage Verification</Accordion.Header>
                        <Accordion.Body>
                          <FormikProvider value={formik}>
                            <Row className="py-2">
                              <ListGroup>
                                <ListGroup.Item>
                                  <Row className="px-5 py-1">
                                    <label>
                                      {stageVerificationDetails.type}
                                    </label>
                                  </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  <Row className="align-items-center">
                                    <Col md={6}>
                                      <span>
                                        Due Date:{" "}
                                        {convertDate(
                                          stageVerificationDetails.dueDate
                                        )}
                                      </span>
                                    </Col>
                                    <Col md={4}>
                                      <div className="text-box">
                                        <TextBox
                                          id="expectedVisitDate"
                                          name="expectedVisitDate"
                                          type="date"
                                          required={true}
                                          row={3}
                                          onChange={formik.handleChange}
                                          // max={convertDate(
                                          //   stageVerificationDetails.dueDate
                                          // )}
                                          min={
                                            currentDate
                                              .toISOString()
                                              .split("T")[0]
                                          }
                                          value={
                                            formik.values.expectedVisitDate
                                          }
                                          error={
                                            formik.errors?.expectedVisitDate
                                          }
                                          touched={
                                            formik.touched?.expectedVisitDate
                                          }
                                          isDisabled={
                                            status === "Informed" ||
                                            status === "Approved"
                                          }
                                        />
                                      </div>
                                    </Col>

                                    <Col md={2}>
                                      <button
                                        type="submit"
                                        className="link-button mx-auto"
                                        onClick={() => {
                                          formik.setFieldValue(
                                            "action",
                                            "inform"
                                          );
                                        }}
                                        disabled={
                                          status === "Informed" ||
                                          status === "Approved"
                                        }
                                      >
                                        Inform
                                      </button>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                                {stageVerificationDetails?.checkList?.map(
                                  (item, index) =>
                                    item?.value && (
                                      <ListGroup.Item key={index}>
                                        <div className="py-2 d-flex justify-content-between">
                                          <div className="d-flex flex-grow-1 gap-2 align-items-center">
                                            <Form.Check
                                              checked={item.status}
                                              label={item.value}
                                              disabled={
                                                status === "Informed" ||
                                                status === "Approved"
                                              }
                                            />
                                          </div>
                                        </div>
                                      </ListGroup.Item>
                                    )
                                )}

                                {type === "Launching" &&
                                  status === "Informed" && (
                                    <ListGroup.Item className="d-flex  justify-content-between p-2 align-items-center">
                                      <Col md={2}>
                                        <TextBox
                                          label={"Length (in meters)"}
                                          placeholder={"Enter Length"}
                                          name="LengthOfVessel"
                                          required={true}
                                          value={formik.values.LengthOfVessel}
                                          error={formik.errors.LengthOfVessel}
                                          touched={
                                            formik.touched?.LengthOfVessel
                                          }
                                          onChange={formik.handleChange}
                                        />
                                      </Col>
                                      <Col md={2}>
                                        <TextBox
                                          label={"Breadth (in meters)"}
                                          name="breadthOfVessel"
                                          required={true}
                                          value={formik.values.breadthOfVessel}
                                          placeholder={"Enter Breadth"}
                                          error={formik.errors.breadthOfVessel}
                                          touched={isTouched(
                                            "breadthOfVessel",
                                            formik
                                          )}
                                          onChange={formik.handleChange}
                                        />
                                      </Col>
                                      <Col md={2}>
                                        <TextBox
                                          label={"Depth (in meters)"}
                                          name="depthOfVessel"
                                          required={true}
                                          value={formik.values.depthOfVessel}
                                          placeholder={"Enter Depth"}
                                          error={formik.errors.depthOfVessel}
                                          touched={isTouched(
                                            "depthOfVessel",
                                            formik
                                          )}
                                          onChange={formik.handleChange}
                                        />
                                      </Col>
                                      <Col
                                        md={3}
                                        className="d-flex flex-column align-items-center bo"
                                      >
                                        {/* <TextBox
                                      label={"Free board marking of the vessel"}
                                      placeholder={
                                        "Enter Free board marking of the vessel"
                                      }
                                      value={
                                        formik.values.free_board_marking_vessel
                                      }
                                      onChange={formik.handleChange}
               b                       error={
                                        formik.errors.free_board_marking_vessel
                                      }
                                      name="free_board_marking_vessel"
                                      touched={isTouched(
                                        "free_board_marking_vessel",
                                        formik
                                      )}
                                    /> */}
                                        <CheckBox
                                          label={
                                            "Free board marking of the vessel "
                                          }
                                          // required={true}
                                          name="free_board_marking_vessel"
                                          value={
                                            formik.values
                                              .free_board_marking_vessel
                                          }
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              "free_board_marking_vessel",
                                              e.target.checked
                                            );
                                          }}

                                          // error={
                                          //   formik.errors.free_board_marking_vessel
                                          // }
                                        />
                                      </Col>
                                    </ListGroup.Item>
                                  )}
                              </ListGroup>
                            </Row>
                            {stageVerificationDetails?.type === "Stability" ? (
                              <PropulsionEngine
                                vesselDataAdditional={vesselDataAdditional}
                                propulsionEngine={propulsionEngine}
                              />
                            ) : null}
                          </FormikProvider>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>

                  {stageVerificationDetails?.type === "Stability" &&
                  status === "Informed" &&
                  vesselData?.vesselCategory?.vesselCategoryName !==
                    "Category A" ? (
                    <div className="card py-3 px-3 gap-3">
                      <h4 className="my-0">Uploads</h4>

                      <Row>
                        <Col
                          md={6}
                          lg={8}
                          className="d-flex align-items-center"
                        >
                          <span className="required-indicator">
                            Photos (maximum upto 6)
                          </span>
                        </Col>
                        <Col md={6} lg={4}>
                          {formik.values?.photos &&
                          formik.values?.photos?.length > 0 ? (
                            formik.values?.photos.map((file, index) => (
                              <div style={{ paddingTop: "10px" }}>
                                <DummyFile
                                  key={index}
                                  fileName={file?.name || "Image Uploaded"}
                                  showDelete={true}
                                  showDownload={!!file} // Show download button if the file exists
                                  onDelete={() => {
                                    const updatedPhotos =
                                      formik.values?.photos.filter(
                                        (_, i) => i !== index
                                      );
                                    formik.setFieldValue(
                                      "photos",
                                      updatedPhotos
                                    );
                                  }}
                                  onDownload={() => {
                                    // Handle download logic here
                                    if (file instanceof File) {
                                      const url = URL.createObjectURL(file);
                                      window.open(url, "_blank"); // Open in a new window for direct download
                                    } else {
                                      // If it's a URL or path, use existing download logic
                                      handleDownloadClick(file); // Replace with your download function if needed
                                    }
                                  }}
                                />
                              </div>
                            ))
                          ) : (
                            <FileSelector
                              id={"photos"}
                              error={formik.errors["photos"]}
                              multiple={true}
                              accept={"image/*"}
                              onChange={(e) => {
                                const files = Array.from(e.target.files);
                                const existingFiles =
                                  formik.values.photos || [];

                                if (existingFiles.length + files.length > 6) {
                                  formik.setFieldError(
                                    "photos",
                                    "You can upload a maximum of 6 images."
                                  );
                                  return;
                                }

                                const isImage = files.every((file) =>
                                  file.type.match("image/*")
                                );
                                if (isImage) {
                                  formik.setFieldValue("photos", [
                                    ...existingFiles,
                                    ...files,
                                  ]);
                                } else {
                                  formik.setFieldError(
                                    "photos",
                                    "Only image files are allowed"
                                  );
                                }
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                      {stabilityDocuments.map((stabilityDocument, key) => (
                        <Row key={key}>
                          <Col
                            md={6}
                            lg={8}
                            className="d-flex align-items-center"
                          >
                            <span className="required-indicator">
                              {stabilityDocument.label}
                            </span>
                          </Col>

                          <Col md={6} lg={4}>
                            {formik.values?.documentUpload?.[key]?.fileName ? (
                              <DummyFile
                                fileName={
                                  formik.values?.documentUpload[key]?.fileName
                                    ?.name || "File Uploaded"
                                }
                                showDelete={true}
                                showDownload={true} // Show the download button if file exists
                                onDelete={() => {
                                  formik.setFieldValue(
                                    `documentUpload[${key}].fileName`,
                                    ""
                                  );
                                }}
                                onDownload={() => {
                                  const uploadedFile =
                                    formik.values?.documentUpload[key]
                                      ?.fileName;

                                  if (uploadedFile instanceof File) {
                                    // If it's a File object, create a URL and open it in a new window/tab
                                    const url =
                                      URL.createObjectURL(uploadedFile);
                                    window.open(url, "_blank"); // Open in a new window for direct download
                                  } else if (uploadedFile) {
                                    // If it's not a File object (e.g., a URL or path), use your existing download logic
                                    handleDownloadClick(uploadedFile); // Replace with your existing download logic if needed
                                  } else {
                                    console.error("No file to download");
                                  }
                                }}
                              />
                            ) : (
                              <FileSelector
                                id={stabilityDocument.value}
                                error={
                                  formik.errors["documentUpload"]?.[key]?.[
                                    "fileName"
                                  ]
                                }
                                accept={stabilityDocument.accept}
                                onChange={(e) => {
                                  if (e.target.files) {
                                    formik.setFieldValue(
                                      `documentUpload.[${key}].fileName`,
                                      e.target.files[0]
                                    );
                                  }
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      ))}
                    </div>
                  ) : null}
                  {stageVerificationDetails?.type === "Stability" &&
                    status === "Informed" && (
                      <>
                        <Row className="mt-3">
                          <Col md={4}>
                            <TextBox
                              label="Passenger Capacity Lower Deck"
                              name="lowerDeck"
                              type="text"
                              placeholder="Enter"
                              required={true}
                              onChange={formik.handleChange}
                              value={formik.values.lowerDeck}
                              touched={isTouched("lowerDeck", formik)}
                              error={formik.errors.lowerDeck}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                          <Col md={4}>
                            <TextBox
                              label="Passenger Capacity Upper Deck"
                              name="upperDeck"
                              type="text"
                              placeholder="Enter"
                              // required={true}
                              onChange={formik.handleChange}
                              value={formik.values.upperDeck}
                              touched={isTouched("upperDeck", formik)}
                              error={formik.errors.upperDeck}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                          <Col md={4}>
                            <TextBox
                              label="Net Tonnage"
                              name="netTonnage"
                              type="text"
                              placeholder="Enter"
                              // required={true}
                              onChange={formik.handleChange}
                              value={formik.values.netTonnage}
                              touched={isTouched("netTonnage", formik)}
                              error={formik.errors.netTonnage}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                          <Col md={4}>
                            <TextBox
                              id="grossTonnage"
                              label="Gross tonnage"
                              placeholder="Enter gross tonnnage"
                              name="grossTonnage"
                              value={formik.values.grossTonnage}
                              onChange={formik.handleChange}
                              type="text"
                              required={true}
                              error={formik.errors?.grossTonnage}
                              touched={formik.touched?.grossTonnage}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  {(stageVerificationDetails?.type === "Stability" &&
                    status === "Informed") ||
                    (status === "Approved" && (
                      <>
                        <Row>
                          <Col md={4}>
                            <TextBoxLive
                              label="Zone of operation"
                              name="zoneOfOperation"
                              id="zoneOfOperation"
                              type="text"
                              placeholder="Enter"
                              required={true}
                              onChange={formik.handleChange}
                              value={formik.values.zoneOfOperation}
                              touched={formik.touched?.zoneOfOperation}
                              error={formik.errors?.zoneOfOperation}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                          <Col md={4}>
                            <TextBoxLive
                              label="Freeboard Assigned"
                              name="minimumFreeboard"
                              id="minimumFreeboard"
                              type="text"
                              placeholder="Enter"
                              required={true}
                              onChange={formik.handleChange}
                              value={formik.values.minimumFreeboard}
                              touched={formik.touched?.minimumFreeboard}
                              error={formik.errors?.minimumFreeboard}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <h4>Matser Details</h4>

                          <Col md={4}>
                            <SelectBox
                              options={cocGrade}
                              value={formik.values.cocGrade}
                              onChange={formik.handleChange}
                              name="cocGrade"
                              label="Master’s COC Grade"
                              isRequired={true}
                              isTouched={formik.touched?.cocGrade}
                              error={formik.errors?.cocGrade}
                              isIdSelect={true}
                            />
                          </Col>
                          <Col md={4}>
                            <TextBoxLive
                              label="No"
                              name="no"
                              id="no"
                              type="text"
                              placeholder="Enter"
                              required={true}
                              onChange={formik.handleChange}
                              value={formik.values.no}
                              touched={formik.touched?.no}
                              error={formik.errors?.no}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                          <Col md={4}>
                            <TextBoxLive
                              label="COC No"
                              name="cocNo"
                              id="cocNo"
                              type="text"
                              placeholder="Enter"
                              required={true}
                              onChange={formik.handleChange}
                              value={formik.values.cocNo}
                              touched={formik.touched?.cocNo}
                              error={formik.errors?.cocNo}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                          <Col md={4}>
                            <TextBoxLive
                              label="Place of Issue"
                              name="placeOfIssue"
                              id="regTonnage"
                              type="text"
                              placeholder="Enter"
                              required={true}
                              onChange={formik.handleChange}
                              value={formik.values.placeOfIssue}
                              touched={formik.touched?.placeOfIssue}
                              error={formik.errors?.placeOfIssue}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <h4>Engine Details</h4>

                          <Col md={4}>
                            <TextBoxLive
                              label="Name of the Engine Driver"
                              name="engineDriverName"
                              id="engineDriverName"
                              type="text"
                              placeholder="Enter"
                              // required={true}
                              onChange={formik.handleChange}
                              value={formik.values.engineDriverName}
                              touched={formik.touched?.engineDriverName}
                              error={formik.errors?.engineDriverName}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                          <Col md={4}>
                            <TextBoxLive
                              label="Engine Driver COC Grade"
                              name="engineDriverCocGrade"
                              id="regTonnage"
                              type="text"
                              placeholder="Enter"
                              // required={true}
                              onChange={formik.handleChange}
                              value={formik.values.engineDriverCocGrade}
                              touched={formik.touched?.engineDriverCocGrade}
                              error={formik.errors?.engineDriverCocGrade}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                          <Col md={4}>
                            <TextBoxLive
                              label="COC No"
                              name="engineCocNo"
                              id="engineCocNo"
                              type="text"
                              placeholder="Enter"
                              required={true}
                              onChange={formik.handleChange}
                              value={formik.values.engineCocNo}
                              touched={formik.touched?.engineCocNo}
                              error={formik.errors?.engineCocNo}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                          <Col md={4}>
                            <TextBoxLive
                              label="Place of Issue"
                              name="placeOfIssueEngine"
                              id="placeOfIssueEngine"
                              type="text"
                              placeholder="Enter"
                              required={true}
                              onChange={formik.handleChange}
                              value={formik.values.placeOfIssueEngine}
                              touched={formik.touched?.placeOfIssueEngine}
                              error={formik.errors?.placeOfIssueEngine}
                              onBlur={formik.handleBlur}
                            />
                          </Col>
                        </Row>
                      </>
                    ))}
                  <Row className="pt-3">
                    {renderStabilityDocuments(stageVerificationDetails, status)}
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="text-box">
                        <TextArea
                          label="Remarks by the vessel owner"
                          id="remarksVo"
                          name="remarksVo"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.remarksVo}
                          touched={isTouched("remarksVo", formik)}
                          error={formik.errors.remarksVo}
                          required={true}
                          row={4}
                          isDisabled={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="text-box">
                        <TextArea
                          label="Remarks"
                          id="remarks"
                          name="remarks"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.remarks}
                          touched={isTouched("remarks", formik)}
                          error={formik.errors.remarks}
                          required={true}
                          row={4}
                          isDisabled={status === "Approved"}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Row>

              {status === "Informed" && (
                <Row className="button-container">
                  <Col className="btn-col address-button d-flex justify-content-center gap-2">
                    <Button
                      label="Reject"
                      type="submit"
                      className="btn-danger btn-outline shadow-none"
                      onClick={() => {
                        formik.setFieldValue("action", "reject");
                        setIsApproved(false);
                      }}
                    />
                    <Button
                      label="Approve"
                      className="btn-default btn-sucess shadow-none"
                      type="submit"
                      onClick={() => {
                        formik.setFieldValue("action", "approve");
                        setIsApproved(true);
                      }}
                      disabled={status !== "Informed"}
                    />
                  </Col>
                </Row>
              )}
            </form>
          </Row>
        </Container>
      ) : null}
      {/* 
      {stageVerificationDetails?.type === "Stability" &&
      status === "Approved" ? (
        <Container className="p-0">
          <Row>
            <div className="forms pt-0 pb-3 px-4">
              <div className="card mt-2 py-3 px-3 gap-3">
                <h2 className="my-0">Stability Documents vo</h2>
                <Row>
                  {stageVerificationDetails &&
                    stageVerificationDetails?.photos?.map((photo, index) => (
                      <Col md={12} lg={6} xl={4} className="py-2" key={index}>
                        <div className="card d-flex flex-row m-0 py-3 px-2 justify-content-between px-3">
                          <span>Photo {index + 1}</span>
                          <button
                            className="d-flex align-items-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                            onClick={() => {
                              hanldeDownloadClick(photo);
                            }}
                          >
                            <AiOutlineDownload />
                            Download
                          </button>
                        </div>
                      </Col>
                    ))}
                  {stageVerificationDetails &&
                    stageVerificationDetails?.documentUpload?.map(
                      (uploadedDocument, index) => (
                        <Col md={12} lg={6} xl={4} className="py-2" key={index}>
                          <div className="card d-flex flex-row m-0 py-3 px-2 justify-content-between px-3">
                            <span>
                              {
                                getFieldLabel(uploadedDocument?.fileTitle)
                                  ?.label
                              }
                            </span>
                            <button
                              className="d-flex align-items-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                              onClick={() => {
                                hanldeDownloadClick(uploadedDocument?.fileName);
                              }}
                            >
                              <AiOutlineDownload />
                              Download
                            </button>
                          </div>
                        </Col>
                      )
                    )}
                </Row>
              </div>
            </div>
          </Row>
        </Container>
      ) : null} */}
      {role === VESSEL_OWNER &&
        renderStabilityDocuments(stageVerificationDetails, status)}
    </Container>
  );
  function renderStabilityDocuments(stageVerificationDetails, status) {
    const isStabilityType = stageVerificationDetails?.type === "Stability";
    const isCategoryA =
      vesselData?.vesselCategory?.vesselCategoryName === "Category A";

    if (
      isStabilityType &&
      ((isCategoryA &&
        ["Approved", "Informed", "Requested"].includes(status)) ||
        (!isCategoryA && status === "Approved"))
    ) {
      return (
        <Container className="p-0">
          <Row>
            <div className="forms pt-0 pb-3 px-4">
              <div className="card mt-2 py-3 px-3 gap-3">
                <h2 className="my-0">Stability Documents</h2>
                <Row>
                  {stageVerificationDetails?.photos?.map((photo, index) => (
                    <Col md={12} lg={6} xl={4} className="py-2" key={index}>
                      <div className="card d-flex flex-row m-0 py-3 px-2 justify-content-between px-3">
                        <span>Photo {index + 1}</span>
                        <button
                          className="d-flex align-items-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                          onClick={() => handleDownloadClick(photo)}
                        >
                          <AiOutlineDownload />
                          Download
                        </button>
                      </div>
                    </Col>
                  ))}
                  {stageVerificationDetails?.documentUpload?.map(
                    (uploadedDocument, index) => (
                      <Col md={12} lg={6} xl={4} className="py-2" key={index}>
                        <div className="card d-flex flex-row m-0 py-3 px-2 justify-content-between px-3">
                          <span>
                            {getFieldLabel(uploadedDocument?.fileTitle)?.label}
                          </span>
                          <button
                            className="d-flex align-items-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                            onClick={() =>
                              handleDownloadClick(uploadedDocument?.fileName)
                            }
                          >
                            <AiOutlineDownload />
                            Download
                          </button>
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              </div>
            </div>
          </Row>
        </Container>
      );
    }
    return null;
  }
}

export default StageVerificationDetails;
