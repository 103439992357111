import { pagination } from "../../../utils/constants";

export const fetchPreApprovalList = (build) => {
  return build.query({
    query: ({ searchParams, page, preApprovalCode, type }) => {
      const params = [];
      if (preApprovalCode) {
        // params.push(`preApprovalCode=${preApprovalCode}`);
      }
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `pre-approval-process/vo/list?${params.join("&")}`;
      return url;
    },
  });
};
export const fetchAllVesselList = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `vessel/list/all?${params.join("&")}`;
      return url;
    },
  });
};
export const fetchPreApprovalPendingList = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/pre-approval-process/pending/list?${params.join("&")}`;
      return url;
    },
  });
};

export const fetchPreApprovalbyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/pre-approval-process/${id}`;
      return url;
    },
  });
};
export const approvePreApprovalProcess = (build) =>
  build.mutation({
    query: ({ id, data }) => ({
      url: `pre-approval-process/approve/${id}`,
      method: "POST",
      body: data,
    }),
  });
export const rejectPreApprovalProcess = (build) =>
  build.mutation({
    query: ({ id, data }) => ({
      url: `pre-approval-process/reject/${id}`,
      method: "POST",
      body: data,
    }),
  });

export const assignPreApprovalProcess = (build) =>
  build.mutation({
    query: ({ id, data }) => ({
      url: `application-process/preapproval/assign/${id}`,
      method: "POST",
      body: data,
    }),
  });

export const fetchUserByRole = (build) => {
  return build.query({
    query: ({ roleCode = "" }) => {
      const params = [];
      if (roleCode) params.push(`roleCode=${roleCode}`);
      params.push("status=true"); // Add status=true to the query
      const url = `/user?${params.join("&")}`;
      return url;
    },
  });
};

export const fetchUserByRoleCode = (build) => {
  return build.query({
    query: ({ roleCode = "" }) => {
      const params = [];
      if (roleCode) params.push(`roleCode=${roleCode}`);
      const url = `/user?${params.join("&")}`;
      return url;
    },
  });
};

export const fetchApprovalProcessFlowbyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/approval-process-flow/${id}`;
      return url;
    },
  });
};

export const savePreapprovalProcess = (build) =>
  build.mutation({
    query: ({ data }) => ({
      url: `pre-approval-process/save`,
      method: "POST",
      body: data,
    }),
  });

export const fetchVesselByName = (build) => {
  return build.query({
    query: ({ vesselName }) => {
      const url = `vessel/filter/vesselName?vesselName=${vesselName}`;
      return url;
    },
  });
};
