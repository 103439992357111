import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, FormikProvider, FieldArray } from "formik";
import * as Yup from "yup";
import { Accordion, Col, ListGroup, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import TextArea from "./TextArea";
import TextBox from "./TextBox";
import SelectBox from "./Selectbox";
import Button from "./Button";
import CheckBox from "./CheckBox";
import { NAVAL_ARCHITECTURE, VESSEL_OWNER } from "../utils/constants";

import CommonModal from "./CommonModal";
import { useUpdateStageVerificationRequestMutation } from "../services/modules/StageVerification";
import "./style.scss";
import { CheckoutPage } from "./CheckoutPage";
import { chargeListType } from "../utils/constants";
import CommonPageTitle from "./CommonPageTitle";
import { numVal } from "../utils/constants";
import { MdDelete } from "react-icons/md";
import FileSelector, { DummyFile } from "./FileSelector";
import { useLazyFetchFeesTypeByIdQuery } from "../services/modules/payment";
import { useUpdateVesselAdditionalDetailMutation } from "../services/modules/vesselowner/preapproval";
import {
  useLazyDownloadFileQuery,
  useUploadFileMutation,
} from "../services/modules/common/file";
import { sideEngineOptions } from "./../utils/constants";
import Loader from "./Loader";
import { uploadFilesInSchema } from "../utils/uploadFilesInSchema";
import { AiOutlineDownload } from "react-icons/ai";
import { getRole } from "../utils/utility";

const StageVerificationPreApproval = ({
  stageVerificationDetails,
  vesselData,
  vesselAdditionalDetail,
  vesselAllDetails,
}) => {
  const [checkList, setCheckList] = useState(null);
  const [stageVerificationId, setStageVerificationId] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [expectedDate, setExpectedDate] = useState(null);
  const [status, setStatus] = useState(false);
  const [type, setType] = useState(null);
  const [remarkNav, setRemarkNav] = useState(false);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const role = getRole();

  const [modalState, setModalState] = useState({
    show: false,
    title: "",
    description: "",
    buttonText: "OK",
    isSuccess: false,
  });
  console.log("stageVerificationDetails", stageVerificationDetails?._id);

  const navigate = useNavigate();

  const [
    updateVesselAdditionalDetail,
    {
      isError: isUpdateVesselAdditionalError,
      error: updateVesselAdditionalError,
      isLoading: isUpdateVesselAdditionalLoading,
    },
  ] = useUpdateVesselAdditionalDetailMutation();
  const [fetchFeesTypeById, { data: fetchFees, isSuccess: isFeesSucess }] =
    useLazyFetchFeesTypeByIdQuery();

  const [uploadFile, { isLoading: isUploadFileLoading }] =
    useUploadFileMutation();

  const [
    updateStageVerificationRequest,
    {
      data: requestData,
      isSuccess: isRequestDataSuccess,
      isError: hasRequestDataFailed,
      error: requestFailedData,
      isLoading: isUpdateStageVerificationRequestLoading,
    },
  ] = useUpdateStageVerificationRequestMutation();
  useEffect(() => {
    if (stageVerificationDetails?._id) {
      fetchFeesTypeById({ id: stageVerificationDetails._id });
    }
  }, [stageVerificationDetails]);
  const [downloadFile, { data, isFetching }] = useLazyDownloadFileQuery();

  const onHandleClickBack = () => {
    //Navigate based on the type
    switch (type) {
      case "Keel laying":
        navigate("/keel-laying-requests");
        break;
      case "Launching":
        navigate("/launching-requests");
        break;
      case "Stability":
        navigate("/stability-requests");
        break;
      default:
        break;
    }
  };

  const onHandleClickBackCheckout = () => {
    switch (type) {
      case "Keel laying":
        window.location.href = "/keel-laying-requests";
        break;
      case "Launching":
        window.location.href = "/launching-requests";
        break;
      case "Stability":
        window.location.href = "/stability-requests";
        break;
      default:
        break;
    }
  };
  const stabilityDocuments = [
    {
      label: "Heel Test",
      value: "documentUpload.[0].fileName",
      accept: "application/pdf",
      field: "heelTest",
    },
    {
      label: "Swamp Test",
      value: "documentUpload.[1].fileName",
      accept: "application/pdf",
      field: "swampTest",
    },
    {
      label: "Inclining Test / Inclination test",
      value: "documentUpload.[2].fileName",
      accept: "application/pdf",
      field: "inclinationTest",
    },
    {
      label: "Calculations report Heel & Inclining Test / Inclination test",
      value: "documentUpload.[3].fileName",
      accept: "application/pdf",
      field: "calculationReport",
    },
    {
      label: "Calculation report with flooding",
      value: "documentUpload.[4].fileName",
      accept: "application/pdf",
      field: "calculationReportWithFlooding",
    },
    {
      label: "Damage stability calculations",
      value: "documentUpload.[5].fileName",
      accept: "application/pdf",
      field: "damageStabilityCalculations",
    },
    {
      label: "Gross Tonnage Calculation",
      value: "documentUpload.[6].fileName",
      accept: "application/pdf",
      field: "grossTonnageCalculation",
    },
    {
      label: "Net tonnage Calculation",
      value: "documentUpload.[7].fileName",
      accept: "application/pdf",
      field: "netTonnageCalculation",
    },
  ];
  const documentUploadFiles = [
    {
      fileTitle: "heelTest",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "swampTest",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "inclinationTest",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "calculationReport",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "calculationReportWithFlooding",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "damageStabilityCalculations",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "grossTonnageCalculation",
      fileType: "application/pdf",
      fileName: "",
    },
    {
      fileTitle: "netTonnageCalculation",
      fileType: "application/pdf",
      fileName: "",
    },
  ];

  //Format the input date into a string with the format "DD-MM-YYYY"
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date?.getFullYear();
    const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
    const day = date?.getDate()?.toString()?.padStart(2, "0");
    return `${day}-${month}-${year}`;
  }

  const formattedDueDate = formatDate(dueDate);
  const formattedExpectedDate = formatDate(expectedDate);

  // const initialValues = {
  //   remarks: "",
  //   remarksNav: "",
  //   purchaseBill: vesselAllDetails?.purchaseBill || "",
  //   propulsionOfEngines: vesselAllDetails?.propulsionOfEngines?.map(
  //     (engine) => ({
  //       noOfSets: engine?.noOfSets || "",
  //       manufacturerNameOrBrand: engine?.manufacturerNameOrBrand || "",
  //       modelNo: engine?.modelNo || "",
  //       sideOfEngine: engine?.sideOfEngine || "",
  //       typeOfEngine: engine?.typeOfEngine || "",
  //       diameterOfPropulsionShaft: engine?.diameterOfPropulsionShaft || "",
  //       materialOfPropulsionShaft: engine?.materialOfPropulsionShaft || "",
  //       noOfGears: engine?.noOfGears || "",
  //       typeOfGears: engine?.typeOfGears || "",

  //       files: {
  //         engineCertificate: engine?.files?.engineCertificate || "",
  //         testCertificate: engine?.files?.testCertificate || "",
  //         builderCertificate: engine?.files?.builderCertificate || "",
  //       },
  //     })
  //   ) || [
  //     {
  //       noOfSets: "",
  //       manufacturerNameOrBrand: "",
  //       modelNo: "",
  //       typeOfEngine: "",
  //       diameterOfPropulsionShaft: "",
  //       materialOfPropulsionShaft: "",
  //       noOfGears: "",
  //       typeOfGears: "",
  //       sideOfEngine: "",

  //       files: {
  //         engineCertificate: "",
  //         testCertificate: "",
  //         builderCertificate: "",
  //       },
  //     },
  //   ],
  // };

  const initialValues = useMemo(() => {
    const remarksVo =
      stageVerificationDetails?.remarks
        ?.filter((remark) => remark?.userId === vesselData?.ownerId)
        .map((remark) => remark?.remark)?.[0] || "";

    const remarksNav =
      stageVerificationDetails?.remarks
        ?.filter(
          (remark) => remark?.userId === stageVerificationDetails?.userId
        )
        .map((remark) => remark?.remark)?.[0] || "";

    return {
      remarks: remarksVo,
      remarksNav: remarksNav,
      purchaseBill: vesselAllDetails?.purchaseBill || "",
      propulsionOfEngines: vesselAllDetails?.propulsionOfEngines?.map(
        (engine) => ({
          noOfSets: engine?.noOfSets || "",
          manufacturerNameOrBrand: engine?.manufacturerNameOrBrand || "",
          modelNo: engine?.modelNo || "",
          typeOfEngine: engine?.typeOfEngine || "",
          diameterOfPropulsionShaft: engine?.diameterOfPropulsionShaft || "",
          materialOfPropulsionShaft: engine?.materialOfPropulsionShaft || "",
          noOfGears: engine?.noOfGears || "",
          typeOfGears: engine?.typeOfGears || "",
          sideOfEngine: engine?.sideOfEngine || "",
          files: {
            engineCertificate: engine?.files?.engineCertificate || "",
            testCertificate: engine?.files?.testCertificate || "",
            builderCertificate: engine?.files?.builderCertificate || "",
          },
        })
      ) || [
        {
          noOfSets: "",
          manufacturerNameOrBrand: "",
          modelNo: "",
          typeOfEngine: "",
          diameterOfPropulsionShaft: "",
          materialOfPropulsionShaft: "",
          noOfGears: "",
          typeOfGears: "",
          sideOfEngine: "",
          files: {
            engineCertificate: "",
            testCertificate: "",
            builderCertificate: "",
          },
          documentUpload: documentUploadFiles,
        },
      ],
    };
  }, [stageVerificationDetails, vesselAllDetails]);

  const validationSchema = Yup.object({
    remarks: Yup.string()
      .required("Remarks field is required!")
      .matches(/.*\S.*/, "Remark cannot be empty or consist of only spaces."),
    purchaseBill: Yup.mixed().when("type", {
      is: () => type === "Stability",
      then: () => Yup.mixed().required("Purchase Bill is required"),
      otherwise: () => Yup.mixed().notRequired(),
    }),

    propulsionOfEngines: Yup.array().when("type", {
      is: () => type === "Stability",
      then: (schema) =>
        schema
          .of(
            Yup.object().shape({
              noOfSets: Yup.string()
                .matches(/^[0-9 ]+$/, "Only  numbers are allowed.")
                .required("No. of sets with BHP of each is required!"),
              manufacturerNameOrBrand: Yup.string()
                .matches(
                  /^[a-zA-Z0-9 ]+$/,
                  "Only characters and numbers are allowed."
                )
                .required("Manufacturers name or Brand is required"),
              modelNo: Yup.string()
                .matches(
                  /^[a-zA-Z0-9 ]+$/,
                  "Only characters and numbers are allowed."
                )
                .required("Model number is required"),
              typeOfEngine: Yup.string()
                .matches(/^[a-zA-Z ]+$/, "Only characters are allowed.")
                .required("Type of engine is required"),
              diameterOfPropulsionShaft: Yup.string().matches(
                /^[0-9 ]+$/,
                "Only numbers are allowed."
              ),
              // documentUpload: Yup.array(Yup.mixed()).when("vesselData", {
              //           is: (vesselData) => {
              //             console.log("Vessel Data:", vesselData); // Debugging
              //             return vesselData?.vesselCategory?.vesselCategoryName !== "Category A";
              //           },
              //           then: Yup.array()
              //             .of(
              //               Yup.object().shape({
              //                 fileTitle: Yup.string().oneOf([
              //                   "heelTest",
              //                   "swampTest",
              //                   "inclinationTest",
              //                   "calculationReport",
              //                   "calculationReportWithFlooding",
              //                   "damageStabilityCalculations",
              //                   "grossTonnageCalculation",
              //                   "netTonnageCalculation",
              //                 ]),
              //                 fileType: Yup.string().oneOf(["application/pdf"]),
              //                 fileName: Yup.mixed()
              //                   .required("File is required")
              //                   .test("fileFormat", "Only pdf files are allowed", (file) => {
              //                     if (file) return file.type.match("application/pdf");
              //                     return true;
              //                   }),
              //               })
              //             )
              //             .length(8, "All files are required"),
              //         }),
              materialOfPropulsionShaft: Yup.string().matches(
                /^[a-zA-Z ]+$/,
                "Only characters are allowed."
              ),
              noOfGears: Yup.string().matches(
                /^[0-9 ]+$/,
                "Only numbers are allowed."
              ),
              typeOfGears: Yup.string().matches(
                /^[a-zA-Z]+$/,
                "Only characters are allowed."
              ),
              sideOfEngine: Yup.string().required("Side of engine is required"),
              files: Yup.object().shape({
                engineCertificate: Yup.mixed().required(
                  "Engine Certificate is required"
                ),
                testCertificate: Yup.mixed().required(
                  "Test Certificate is required"
                ),
                builderCertificate: Yup.mixed().required(
                  "Builder Certificate is required"
                ),
              }),
            })
          )
          .required()
          .min(1, "At least one propulsion engine is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  //update stage verification request
  const onSubmit = async (values) => {
    const { remarks, documentUpload, photos } = values;
    const checklistData = checkList?.map((item) => ({
      value: item?.value,
      status: item?.status,
    }));

    let postData = {
      checkList: checklistData,
      remarks: [
        {
          remark: remarks,
        },
      ],
    };

    const { propulsionOfEngines, purchaseBill } = formik.values;

    const postDataStability = {
      vesselId: vesselData?._id,
      purchaseBill,
      propulsionOfEngines,
    };
    if (type === "Stability") {
      postData["documentUpload"] = documentUpload;
      postData["photos"] = photos;
      const uploadedPostdata = await uploadFilesInSchema(postData);
      postData = uploadedPostdata;
      console.log("uploadedPostdata", uploadedPostdata);
    }
    const uploadPurchaseFile = async () => {
      const file = purchaseBill;
      if (file instanceof File) {
        const formData = new FormData();
        formData.append("file", file);
        console.log(file, "FILE");

        const response = await uploadFile({ data: formData });

        if (response?.data?.statusCode === 201) {
          const fileNameOnServer = response?.data?.result?.at(0)?.filename;
          return fileNameOnServer;
        }
      }
    };

    const fileUploadPromises = postDataStability?.propulsionOfEngines?.map(
      (engine, index) => {
        // Map the file processing promises
        const filePromises = Object.entries(engine?.files)?.map(
          async ([type, file]) => {
            if (file instanceof File) {
              const formData = new FormData();
              formData.append("file", file);

              try {
                const response = await uploadFile({ data: formData });

                if (response?.data?.statusCode === 201) {
                  const fileNameOnServer =
                    response?.data?.result?.at(0)?.filename;
                  postDataStability.propulsionOfEngines[index].files[type] =
                    fileNameOnServer;

                  formik.setFieldValue(
                    `propulsionOfEngines[${index}].files.${type}`,
                    fileNameOnServer
                  );
                }
              } catch (error) {
                console.error(`Error uploading file of type ${type}:`, error);
              }
            } else {
              console.log("Already submitted file");
            }
          }
        );

        // Return a promise that resolves when all files for this engine have been processed
        return Promise.all(filePromises);
      }
    );

    try {
      // Wait for all engines' file uploads to complete
      const purchaseBillFileName = await uploadPurchaseFile();
      if (purchaseBillFileName) {
        postDataStability.purchaseBill = purchaseBillFileName;
        formik.setFieldValue("purchaseBill", purchaseBillFileName);
      }
      await Promise.all(fileUploadPromises);

      // Now you can update the vessel additional details
      const response = await updateVesselAdditionalDetail({
        id: vesselAdditionalDetail,
        data: postData,
      });

      if (response?.data?.statusCode === 200) {
        console.log("Vessel additional details updated successfully.");

        // Now update the stage verification request
        const isSubmit =
          type === "Stability" &&
          vesselData?.vesselCategory?.vesselCategoryName === "Category A";
        const isProceedToPay = type === "Stability";

        // Now update the stage verification request
        if (isSubmit) {
          await updateStageVerificationRequest({
            data: postData,
            id: stageVerificationId,
          });
          console.log("Stage verification request updated successfully.");
          // setShowPayment(false);
          navigate("/stability-requests");
        } else if (isProceedToPay) {
          await updateStageVerificationRequest({
            data: postData,
            id: stageVerificationId,
          });
          console.log("Stage verification request updated successfully.");
          setShowPayment(true);
        }
        // Handle all other types
        else {
          await updateStageVerificationRequest({
            data: postData,
            id: stageVerificationId,
          });
          console.log("Stage verification request updated successfully.");
        }
      } else {
        console.error("Failed to update vessel additional details:", response);
      }
    } catch (error) {
      console.error("Error updating vessel additional details:", error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const isTouched = (fieldName, formik) => {
    const isCondition = formik.touched[fieldName] && formik.errors[fieldName];
    return isCondition ? true : false;
  };

  useEffect(() => {
    console.log("Updated Formik Values:", formik.values);
  }, [formik.values]);

  useEffect(() => {
    //update the component state with the stage verification details when they are available
    if (stageVerificationDetails) {
      const checkList = stageVerificationDetails?.checkList;
      const id = stageVerificationDetails?._id;
      const stageStatus = stageVerificationDetails?.status;
      const remarks = stageVerificationDetails?.remarks;

      setCheckList(checkList);
      setStageVerificationId(id);
      setDueDate(stageVerificationDetails?.dueDate);
      setExpectedDate(stageVerificationDetails?.expectedVisitDate);
      setStatus(stageStatus === "Pending" || stageStatus === "Rejected");
      setType(stageVerificationDetails?.type);
      setIsPaymentComplete(
        stageVerificationDetails.paymentStatus === "captured"
      );

      const remarksNav = remarks
        ?.filter(
          (remark) => remark?.userId === stageVerificationDetails?.userId
        )
        .map((remark) => remark.remark);

      if (remarksNav?.length > 0) {
        setRemarkNav(true);
      }
    }
  }, [stageVerificationDetails, vesselData]);

  useEffect(() => {
    if (requestData?.statusCode === 201 || requestData?.statusCode === 200) {
    }
  }, [requestData]);

  //show the modal when stage verification is requested
  useEffect(() => {
    if (isRequestDataSuccess) {
      setModalState({
        title: requestData.message,
        show: true,
        isSuccess: true,
      });
    } else if (hasRequestDataFailed) {
      setModalState({
        title: requestFailedData?.data?.message,
        show: true,
        isSuccess: false,
      });
    }
  }, [
    requestData,
    isRequestDataSuccess,
    hasRequestDataFailed,
    requestFailedData,
  ]);

  //Handle the change of checkbox status for an item in the checklist
  const handleCheckboxChange = (index) => {
    const updatedChecklistData = [...checkList];
    updatedChecklistData[index] = {
      ...updatedChecklistData[index],
      status: !updatedChecklistData[index].status,
    };
    setCheckList(updatedChecklistData);
  };
  function getFieldLabel(field) {
    return stabilityDocuments.find((doc) => doc.field === field);
  }

  const handleDownloadClick = (fileName) => {
    downloadFile({ fileName })
      .then((response) => {
        window.open(response.data, "_blank");
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };
  const rateTypes = isFeesSucess ? fetchFees?.result?.rateTypes || [] : [];
  const totalAmountBeforeTax = rateTypes.reduce(
    (sum, item) => sum + item.amount,
    0
  );
  const taxes = []; // Add tax logic here if applicable
  const totalTaxAmount = 0; // Compute tax logic if applicable
  const finalAmount = totalAmountBeforeTax + totalTaxAmount;

  if (showPayment)
    return (
      <CheckoutPage
        applicationType={chargeListType?.stability}
        applicationId={stageVerificationId}
        receipt={stageVerificationId}
        description="Stage Verification Application"
        onSuccess={onHandleClickBackCheckout}
        onFailure={onHandleClickBackCheckout}
      />
    );

  if (
    isUpdateStageVerificationRequestLoading ||
    isUpdateVesselAdditionalLoading ||
    isUploadFileLoading
  )
    return <Loader />;

  return (
    <Container className="dashboard-container">
      <CommonPageTitle
        title="Back"
        arrowback={true}
        handleClickBack={onHandleClickBack}
      />
      <form className="forms" onSubmit={formik.handleSubmit}>
        <div className="card">
          <Accordion defaultActiveKey={["0"]} alwaysOpen flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header> Stage Verification </Accordion.Header>
              <Accordion.Body>
                <Container style={{ padding: "10px" }}>
                  <CommonModal
                    {...modalState}
                    buttonText="OK"
                    description=""
                    handleClose={() => {
                      setModalState((prev) => {
                        return { ...prev, show: false };
                      });
                      if (modalState.isSuccess) onHandleClickBack();
                    }}
                  />
                  <ListGroup as="ul" style={{ borderRadius: "0.5rem" }}>
                    <ListGroup.Item
                      as="li"
                      style={{
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      <Row className="d-flex">
                        <Col md={2}>{type}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item as="li">
                      <Row>
                        <Col>Due date: {formattedDueDate}</Col>
                        <Col className="d-flex justify-content-end">
                          {expectedDate
                            ? `Expected date of visit: ${formattedExpectedDate}`
                            : null}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    {checkList &&
                      checkList.map((item, index) =>
                        item.value ? (
                          <ListGroup.Item as="li" key={index}>
                            <Row>
                              <Col xs="auto">
                                <CheckBox
                                  label={item?.value}
                                  id={`checkbox-${index}`}
                                  style={{ color: "green", fontSize: "16px" }}
                                  onChange={() => handleCheckboxChange(index)}
                                  checked={item.status}
                                  isDisabled={!status}
                                />
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ) : null
                      )}
                  </ListGroup>

                  <Row>
                    <Col md={12}>
                      <div
                        className="text-box"
                        style={{ marginTop: "8px", width: " 100%" }}
                      >
                        <TextArea
                          label="Remarks"
                          id="remarks"
                          name="remarks"
                          type="text"
                          required={true}
                          onChange={formik.handleChange}
                          value={formik.values?.remarks}
                          touched={formik?.touched?.remarks}
                          error={formik.errors?.remarks}
                          row={4}
                          disabled={!status}
                        />
                      </div>
                      <div>
                        {type === "Stability" && (
                          <div>
                            <form onSubmit={formik.handleSubmit}>
                              <Row>
                                <Col md={4}>
                                  {formik.values?.purchaseBill ? (
                                    <DummyFile
                                      fileName={"File"}
                                      label={"LSA/FSA Purchase Bill *"}
                                      showDelete={true}
                                      showDownload={
                                        !!formik.values.purchaseBill
                                      } // Ensure it's a boolean
                                      onDelete={() => {
                                        console.log(
                                          "Deleting file:",
                                          formik.values?.purchaseBill
                                        );
                                        formik.setFieldValue(
                                          "purchaseBill",
                                          ""
                                        );
                                      }}
                                      onDownload={() => {
                                        const uploadedFile =
                                          formik.values.purchaseBill;

                                        if (uploadedFile instanceof File) {
                                          const url =
                                            URL.createObjectURL(uploadedFile);
                                          window.open(url, "_blank"); // Open in a new window
                                        } else {
                                          handleDownloadClick(uploadedFile); // Use existing download logic
                                        }
                                      }}
                                    />
                                  ) : (
                                    <FileSelector
                                      label={"LSA/FSA Purchase Bill"}
                                      name={"purchaseBill"}
                                      required={true}
                                      onChange={(e) => {
                                        const file = e.target.files[0];
                                        formik.setFieldValue(
                                          "purchaseBill",
                                          file
                                        );
                                      }}
                                      error={formik?.errors?.purchaseBill}
                                      touched={formik?.touched?.purchaseBill}
                                      accept="application/pdf"
                                    />
                                  )}
                                </Col>
                              </Row>
                              <PropulsionOfEngines
                                isEdit={true}
                                formik={formik}
                                handleDownloadClick={handleDownloadClick}
                              />
                              {stageVerificationDetails?.type === "Stability" &&
                              vesselData?.vesselCategory?.vesselCategoryName ===
                                "Category A" &&
                              stageVerificationDetails?.status === "Pending" ? (
                                <div className="card py-3 px-3 gap-3">
                                  {console.log("vesselData", vesselData)}
                                  <h4 className="my-0">Uploads</h4>
                                  <Row>
                                    <Col
                                      md={6}
                                      lg={8}
                                      className="d-flex align-items-center"
                                    >
                                      <span className="required-indicator">
                                        Photos (maximum upto 6)
                                      </span>
                                    </Col>
                                    <Col md={6} lg={4}>
                                      {formik.values?.photos &&
                                      formik.values?.photos?.length > 0 ? (
                                        formik.values?.photos.map(
                                          (file, index) => (
                                            <div style={{ paddingTop: "10px" }}>
                                              <DummyFile
                                                key={index}
                                                fileName={
                                                  file?.name || "Image Uploaded"
                                                }
                                                showDelete={true}
                                                onDelete={() => {
                                                  const updatedPhotos =
                                                    formik.values?.photos.filter(
                                                      (_, i) => i !== index
                                                    );
                                                  formik.setFieldValue(
                                                    "photos",
                                                    updatedPhotos
                                                  );
                                                }}
                                              />
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <FileSelector
                                          id={"photos"}
                                          error={formik.errors["photos"]}
                                          multiple={true}
                                          accept={"image/*"}
                                          onChange={(e) => {
                                            const files = e.target.files;
                                            if (files) {
                                              const isImage = [...files].every(
                                                (file) =>
                                                  file.type.match("image/*")
                                              );
                                              if (isImage) {
                                                formik.setFieldValue("photos", [
                                                  ...e.target.files,
                                                ]);
                                              } else {
                                                formik.setFieldError(
                                                  "photos",
                                                  "Only image files are allowed"
                                                );
                                              }
                                            }
                                          }}
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                  {stabilityDocuments.map(
                                    (stabilityDocument, key) => (
                                      <Row key={key}>
                                        <Col
                                          md={6}
                                          lg={8}
                                          className="d-flex align-items-center"
                                        >
                                          <span className="required-indicator">
                                            {stabilityDocument.label}
                                          </span>
                                        </Col>

                                        <Col md={6} lg={4}>
                                          {formik.values?.documentUpload?.[key]
                                            ?.fileName ? (
                                            <DummyFile
                                              fileName={
                                                formik.values?.documentUpload[
                                                  key
                                                ]?.fileName?.name ||
                                                "File Uploaded"
                                              }
                                              showDelete={true}
                                              onDelete={() => {
                                                formik.setFieldValue(
                                                  `documentUpload.[${key}].fileName`,
                                                  ""
                                                );
                                              }}
                                            />
                                          ) : (
                                            <FileSelector
                                              id={stabilityDocument.value}
                                              error={
                                                formik.errors[
                                                  "documentUpload"
                                                ]?.[key]?.["fileName"]
                                              }
                                              accept={stabilityDocument.accept}
                                              onChange={(e) => {
                                                if (e.target.files) {
                                                  formik.setFieldValue(
                                                    `documentUpload.[${key}].fileName`,
                                                    e.target.files[0]
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                        </Col>
                                      </Row>
                                    )
                                  )}
                                </div>
                              ) : null}
                              {/* <Row className="pt-3">
                                      {renderStabilityDocuments(stageVerificationDetails, status)}
                                    </Row> */}
                            </form>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {remarkNav ? (
                      <Col md={12}>
                        <div className="text-box">
                          <TextArea
                            label="Remarks by the Naval Architect"
                            id="remarksNav"
                            name="remarksNav"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.remarksNav}
                            touched={isTouched("remarksNav", formik)}
                            error={formik.errors.remarksNav}
                            required={true}
                            row={4}
                            isDisabled={true}
                          />
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                  {type === "Stability" && (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <strong>Fee Details</strong>
                        </Accordion.Header>
                        <Accordion.Body>
                          {rateTypes.length > 0 ? (
                            <>
                              {rateTypes.map((rate) => (
                                <div
                                  className="row"
                                  style={{ marginTop: "20px" }}
                                  key={rate.type}
                                >
                                  <div className="col-8">
                                    <h6>{rate.type}</h6>
                                  </div>
                                  <div className="col-4 text-end">
                                    <h6>₹{rate.amount}</h6>
                                  </div>
                                </div>
                              ))}

                              <div className="d-flex justify-content-between border-top pt-2">
                                <h5>Subtotal</h5>
                                <h5>₹{totalAmountBeforeTax}</h5>
                              </div>

                              {taxes.length > 0 ? (
                                <>
                                  <h6 className="mt-3">Taxes:</h6>
                                  {taxes.map((tax) => (
                                    <div
                                      className="d-flex justify-content-between"
                                      key={tax._id}
                                    >
                                      <h6>{tax.taxType}</h6>
                                      <h6>{tax.percentage}%</h6>
                                    </div>
                                  ))}
                                  <div className="d-flex justify-content-between border-top pt-2">
                                    <h5>Total Tax</h5>
                                    <h5>₹{totalTaxAmount.toFixed(2)}</h5>
                                  </div>
                                </>
                              ) : (
                                <div
                                  className="alert alert-warning"
                                  role="alert"
                                >
                                  No tax applicable.
                                </div>
                              )}

                              <div className="d-flex justify-content-between mt-3 border-top pt-2">
                                <h4>Total Payable</h4>
                                <h4>₹{finalAmount.toFixed(2)}</h4>
                              </div>
                            </>
                          ) : (
                            <div className="alert alert-warning" role="alert">
                              Application fee is not configured.
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}

                  <Container style={{ paddingTop: "30px" }}>
                    <Row className="button-container">
                      <Col className="btn-col address-button d-flex justify-content-center gap-2">
                        <Button
                          label="Cancel"
                          type="button"
                          className="btn-default btn-outline"
                          onClick={() => {
                            onHandleClickBack();
                          }}
                        />
                        <Button
                          label={
                            type === "Stability"
                              ? vesselData?.vesselCategory
                                  ?.vesselCategoryName === "Category A"
                                ? "Submit"
                                : "Proceed to Pay"
                              : "Request"
                          }
                          className="btn-default btn-success"
                          type="submit"
                          disabled={!status}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </form>
      {role === NAVAL_ARCHITECTURE &&
        renderStabilityDocuments(stageVerificationDetails, status)}
    </Container>
  );

  function renderStabilityDocuments(stageVerificationDetails, status) {
    if (
      stageVerificationDetails?.type === "Stability" &&
      vesselData?.vesselCategory?.vesselCategoryName !== "Category A"
    ) {
      return (
        <Container className="p-0">
          <Row>
            <div className="forms pt-0 pb-3 px-4">
              <div className="card mt-2 py-3 px-3 gap-3">
                <h2 className="my-0">Stability Documents</h2>
                <Row>
                  {stageVerificationDetails?.photos?.map((photo, index) => (
                    <Col md={12} lg={6} xl={4} className="py-2" key={index}>
                      <div className="card d-flex flex-row m-0 py-3 px-2 justify-content-between px-3">
                        <span>Photo {index + 1}</span>
                        <button
                          className="d-flex align-items-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                          onClick={() => handleDownloadClick(photo)}
                        >
                          <AiOutlineDownload />
                          Download
                        </button>
                      </div>
                    </Col>
                  ))}
                  {stageVerificationDetails?.documentUpload?.map(
                    (uploadedDocument, index) => (
                      <Col md={12} lg={6} xl={4} className="py-2" key={index}>
                        <div className="card d-flex flex-row m-0 py-3 px-2 justify-content-between px-3">
                          <span>
                            {getFieldLabel(uploadedDocument?.fileTitle)?.label}
                          </span>
                          <button
                            className="d-flex align-items-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                            onClick={() =>
                              handleDownloadClick(uploadedDocument?.fileName)
                            }
                          >
                            <AiOutlineDownload />
                            Download
                          </button>
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              </div>
            </div>
          </Row>
        </Container>
      );
    }
    return null;
  }
};

function PropulsionOfEngines({
  isEdit = true,
  formik,
  mode,
  handleDownloadClick,
}) {
  const propulsionOfEngines = {
    noOfSets: "",
    manufacturerNameOrBrand: "",
    modelNo: "",
    typeOfEngine: "",
    diameterOfPropulsionShaft: "",
    materialOfPropulsionShaft: "",
    noOfGears: "",
    typeOfGears: "",

    files: {
      engineCertificate: "",
      testCertificate: "",
      builderCertificate: "",
    },
  };

  const { values, errors, onBlur, touched, handleChange, setFieldValue } =
    formik;

  const showDelete = () => {
    return values.propulsionOfEngines.length > 1;
  };

  const fileUploadFields = [
    {
      name: "engineCertificate",
      label: "Engine Certificate",
    },
    {
      name: "testCertificate",
      label: "Test Certificate",
    },

    {
      name: "builderCertificate",
      label: "Builder Certificate",
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <FieldArray name="propulsionOfEngines">
          {({ remove, push }) => (
            <>
              <div className="d-flex flex-column gap-2">
                {values?.propulsionOfEngines?.map((value, index) => (
                  <>
                    <div className="card p-3 mt-5">
                      <Row className="pb-3" key={index}>
                        <Row className="d-flex justify-items-between">
                          <Col md={11}>
                            <h2>Engine {index + 1}</h2>
                          </Col>
                          {showDelete() ? (
                            <Col md={1}>
                              <button
                                className="w-100 border-0 bg-transparent text-end"
                                type="button"
                                onClick={() => remove(index)}
                              >
                                <MdDelete size={"1.2rem"} color="#3d57a7" />
                              </button>
                            </Col>
                          ) : null}
                        </Row>
                        <Col md={4}>
                          <TextBox
                            label="Number of sets with BHP of each"
                            name={`propulsionOfEngines[${index}].noOfSets`}
                            onChange={handleChange}
                            value={value?.noOfSets}
                            required={true}
                            error={
                              errors?.propulsionOfEngines?.at(index)?.noOfSets
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)?.noOfSets
                            }
                            onBlur={onBlur}
                            options={numVal}
                            isDisabled={!isEdit}
                          />
                        </Col>
                        <Col md={4}>
                          <TextBox
                            label="Manufacturers Name/Brand"
                            name={`propulsionOfEngines[${index}].manufacturerNameOrBrand`}
                            id="manufacturerNameOrBrand"
                            type="text"
                            placeholder="Enter"
                            required={true}
                            onChange={handleChange}
                            value={value?.manufacturerNameOrBrand}
                            error={
                              errors?.propulsionOfEngines?.at(index)
                                ?.manufacturerNameOrBrand
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)
                                ?.manufacturerNameOrBrand
                            }
                            onBlur={onBlur}
                            isDisabled={!isEdit}
                          />
                        </Col>
                        <Col md={4}>
                          <TextBox
                            label="Model Number/Engine Serial Number"
                            name={`propulsionOfEngines[${index}].modelNo`}
                            type="text"
                            placeholder="Enter"
                            required={true}
                            onChange={handleChange}
                            value={value.modelNo}
                            error={
                              errors?.propulsionOfEngines?.at(index)?.modelNo
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)?.modelNo
                            }
                            onBlur={onBlur}
                            isDisabled={!isEdit}
                          />
                        </Col>
                        <Col md={4}>
                          <TextBox
                            label="Type of Engine"
                            name={`propulsionOfEngines[${index}].typeOfEngine`}
                            placeholder="Enter"
                            required={true}
                            onChange={handleChange}
                            value={value?.typeOfEngine}
                            error={
                              errors?.propulsionOfEngines?.at(index)
                                ?.typeOfEngine
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)
                                ?.typeOfEngine
                            }
                            onBlur={onBlur}
                            isDisabled={!isEdit}
                          />
                        </Col>
                        <Col md={4}>
                          <TextBox
                            label="Diameter of propulsion shaft"
                            name={`propulsionOfEngines[${index}].diameterOfPropulsionShaft`}
                            placeholder="Enter"
                            onChange={handleChange}
                            // required={true}
                            value={value?.diameterOfPropulsionShaft}
                            error={
                              errors?.propulsionOfEngines?.at(index)
                                ?.diameterOfPropulsionShaft
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)
                                ?.diameterOfPropulsionShaft
                            }
                            onBlur={onBlur}
                            isDisabled={!isEdit}
                          />
                        </Col>
                        <Col md={4}>
                          <TextBox
                            label="Material of propulsion shaft"
                            name={`propulsionOfEngines[${index}].materialOfPropulsionShaft`}
                            type="text"
                            placeholder="Enter"
                            // required={true}
                            onChange={handleChange}
                            value={value?.materialOfPropulsionShaft}
                            error={
                              errors?.propulsionOfEngines?.at(index)
                                ?.materialOfPropulsionShaft
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)
                                ?.materialOfPropulsionShaft
                            }
                            onBlur={onBlur}
                            isDisabled={!isEdit}
                          />
                        </Col>
                        <Col md={4}>
                          <SelectBox
                            label="No of gears"
                            name={`propulsionOfEngines[${index}].noOfGears`}
                            onChange={handleChange}
                            value={value?.noOfGears}
                            // isRequired={true}
                            error={
                              errors?.propulsionOfEngines?.at(index)?.noOfGears
                            }
                            isTouched={
                              touched?.propulsionOfEngines?.at(index)?.noOfGears
                            }
                            onBlur={onBlur}
                            options={numVal}
                            isDisabled={!isEdit}
                          />
                        </Col>
                        <Col md={4}>
                          <TextBox
                            label="Type of gears"
                            name={`propulsionOfEngines[${index}].typeOfGears`}
                            placeholder="Enter"
                            onChange={handleChange}
                            value={value?.typeOfGears}
                            // required={true}
                            error={
                              errors?.propulsionOfEngines?.at(index)
                                ?.typeOfGears
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)
                                ?.typeOfGears
                            }
                            onBlur={onBlur}
                            isDisabled={!isEdit}
                          />
                        </Col>
                        <Col md={4}>
                          <SelectBox
                            label="Side of Engine"
                            name={`propulsionOfEngines[${index}].sideOfEngine`}
                            placeholder="Enter"
                            options={sideEngineOptions}
                            onChange={handleChange}
                            value={value?.sideOfEngine}
                            isRequired={true}
                            error={
                              errors?.propulsionOfEngines?.at(index)
                                ?.sideOfEngine
                            }
                            isTouched={
                              touched?.propulsionOfEngines?.at(index)
                                ?.sideOfEngine
                            }
                            onBlur={onBlur}
                            // isDisabled={true}
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="card p-3">
                      <h2>File Upload</h2>
                      <Row>
                        {fileUploadFields?.map(({ name, label }, key) => (
                          <Col md={4} className="py-2" key={key}>
                            {formik.values?.propulsionOfEngines?.at(index)
                              ?.files?.[name] ? (
                              <DummyFile
                                fileName={"File"}
                                label={`${label}*`}
                                showDelete={mode !== "view"}
                                showDownload={
                                  !!formik.values?.propulsionOfEngines?.[index]
                                    ?.files?.[name]
                                } // Ensure boolean
                                onDelete={() =>
                                  formik.setFieldValue(
                                    `propulsionOfEngines[${index}].files.${name}`,
                                    ""
                                  )
                                }
                                onDownload={() => {
                                  const uploadedFile =
                                    formik.values?.propulsionOfEngines?.[index]
                                      ?.files?.[name];

                                  if (uploadedFile instanceof File) {
                                    const url =
                                      URL.createObjectURL(uploadedFile);
                                    window.open(url, "_blank"); // Open in a new window
                                  } else {
                                    handleDownloadClick(uploadedFile); // Use existing download logic
                                  }
                                }}
                              />
                            ) : (
                              <FileSelector
                                label={label}
                                name={`propulsionOfEngines[${index}].files.${name}`}
                                required={true}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `propulsionOfEngines[${index}].files.${name}`,
                                    e.target.files[0]
                                  );
                                }}
                                error={
                                  formik.touched?.propulsionOfEngines &&
                                  formik.errors?.propulsionOfEngines?.at(index)
                                    ?.files?.[name]
                                }
                                accept="application/pdf"
                              />
                            )}
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </>
                ))}
              </div>

              <Row>
                <div className="add-button">
                  <button
                    className="link-button"
                    type="button"
                    onClick={() => push(propulsionOfEngines)}
                  >
                    + Add Engine
                  </button>
                </div>
              </Row>
            </>
          )}
        </FieldArray>
      </FormikProvider>
    </form>
  );
}

export default StageVerificationPreApproval;
